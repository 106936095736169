import React, { PureComponent } from 'react'
import { Spin, Icon } from 'antd'
import PropTypes from 'prop-types'
import getAppConfig from '../utility/getAppConfig'

class CircularLoader extends PureComponent {
  static propTypes = {
    isSpining: PropTypes.bool,
    tip: PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.string
  };

  render () {
    return (
      <Spin
        spinning={this.props.isSpining || false}
        indicator={
          <Icon
            type='loading'
            style={{
              fontSize: this.props.size || '24px',
              color:
                this.props.color || getAppConfig('SECONDARY_COLOR', '#0180c8')
            }}
            spin
          />
        }
        tip={this.props.tip}
      >
        {this.props.children}
      </Spin>
    )
  }
}

export default CircularLoader
