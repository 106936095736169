import React, { Component, Suspense } from 'react'
import qs from 'qs'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { IntlProvider } from 'react-intl'
import { LocaleProvider, Icon } from 'antd'
import PropTypes from 'prop-types'
import TagManager from 'react-gtm-module'

import App from './containers/App/App'
import asyncComponent from './helpers/AsyncFunc'
import ShippingHeader from './components/Header/shippingHeader'
import appActions from './redux/app/actions'
import { language } from './settings'
import { ThemeProvider } from 'styled-components'
import _ from 'lodash/object'
import CircularLoader from './components/CircularLoader'
import { COLORS } from './constants/colors'
import HeaderV2 from './components/HeaderV2/headerV2.js'

const { changeLang, getDomainConfigs } = appActions

let generateOtp = asyncComponent(() => import('./containers/Login/GenerateOtp'))
let generateGenericOtp = asyncComponent(() => import('./containers/Login/GenericGenerateOtp'))

let feedback = asyncComponent(() => import('./containers/Feedback/feedbackEmail'))
let shipping = asyncComponent(() => import('./containers/Shipping'))
let ShippingReturn = asyncComponent(() => import('./containers/Shipping/return'))
const tokopediaEndorse = asyncComponent(() => import('./containers/TokopediaEndorse'))
const TokopediaCrackScreenEndorse = asyncComponent(() => import('./containers/TokopediaCrackScreenEndorse'))
const GosureCoronaEndorse = asyncComponent(() => import('./containers/Endorsements/GosureCorona'))
const firstMediaEndorse = asyncComponent(() => import('./containers/FirstMediaEndorse'))
const termsAndCondition = asyncComponent(() => import('./containers/mobile/index.js'))
const whiteLabelGenerateOtp = asyncComponent(() => import('./containers/Login/WhiteLabelGenerateOtp'))
const privacy = asyncComponent(() => import('./containers/mobile/privacy.js'))
const vehicleTerms = asyncComponent(() => import('./containers/mobile/VehicleTerms.js'))
const referralTerms = asyncComponent(() => import('./containers/mobile/mobileTerms.js'))
const mobileLockGif = asyncComponent(() => import('./containers/mobile/mobileAppLock.js'))
const lazadaCrackScreen = asyncComponent(() => import('./containers/Endorsements/LazadaCrackScreenEndorsement'))
const lazadaGadgetProtection = asyncComponent(() => import('./containers/Endorsements/LazadaFullGadgetProtection'))
const goVietHotelStayProtection = asyncComponent(() => import('./containers/Endorsements/GoVietHotelStayProtectionEndorsement'))
const lazadaVehicleProtection = asyncComponent(() => import('./containers/Endorsements/LazadaVehicleEndorsement'))
const lazadaHospitalCashPlan = asyncComponent(() => import('./containers/Endorsements/LazadaHospitalCashPlanEndorsement'))
const shopeeEndorsementFullGadget = asyncComponent(() => import('./containers/Endorsements/ShopeeEndorsement/fullGadgetEndorsement'))
const shopeeEndorsementCrackScreen = asyncComponent(() => import('./containers/Endorsements/ShopeeEndorsement/crackScreenEndorsement'))
const gosureGamerEndorsement = asyncComponent(() => import('./containers/Endorsements/GosureAIA/gamer'))
const gosurePAEndorsement = asyncComponent(() => import('./containers/Endorsements/GosureAIA/personalAccident'))
const gosureCancerEndorsement = asyncComponent(() => import('./containers/Endorsements/GosureAIA/cancer'))
const gosureTermLifeEndorsement = asyncComponent(() => import('./containers/Endorsements/GosureAIA/termLifeProtection'))
const rukitaVehicleEndorsement = asyncComponent(() => import('./containers/Endorsements/RukitaMotorVehicleEndorsement'))
const shopeeVatGadgetProtectionEndorsement = asyncComponent(() => import('./containers/Endorsements/ShopeeEndorsement/VietnamFullGadgetEndorsement'))
const mitraSSOLogin = asyncComponent(() => import('./containers/SSO'))
const shopeeThaiElectronicsEndorsement = asyncComponent(() => import('./containers/Endorsements/ShopeeEndorsement/ThaiElectronicsEndorsement'))
const shopeeVatMobileInsuranceEndorsement = asyncComponent(() => import('./containers/Endorsements/ShopeeEndorsement/VietnamMobileInsuranceEndorsement'))
const moladinEndorsement = asyncComponent(() => import('./containers/Endorsements/CreditInsurance/MoladinEndorsement'))
const lazadaProductLiabilityEndorsement = asyncComponent(() => import('./containers/Endorsements/LazadaProductLiabilityEndorsement/'))

const RestrictedRoutes = ({
  component: Component,
  isLoggedIn,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => isLoggedIn
        ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/generate-otp',
              state: {
                from: props.location.pathname
              }
            }}
          />
        )}
    />
  )
}

class PublicRoutes extends Component {
  componentDidMount () {
    const isSendoPAEndorse = ((((this.props.history || {}).location) || {}).pathname || '').indexOf('/sendo/endorse/') > -1
    const isSendoBikeEndorse = ((((this.props.history || {}).location) || {}).pathname || '').indexOf('/sendo-bike/endorse/') > -1
    const isGoVietEndorse = ((((this.props.history || {}).location) || {}).pathname || '').indexOf('/goviet-hotel-stay-protection/endorse/') > -1
    const isShopeevn = ((((this.props.history || {}).location) || {}).pathname || '').indexOf('/shopeethai/') > -1
    const isLazadaVn = ((((this.props.history || {}).location) || {}).pathname || '').indexOf('/product-liability/lazada/endorse/') > -1

    const isGoLife = ((((this.props.history || {}).location) || {}).pathname || '').indexOf('go-life') > -1
    const url = this.props.history.location.pathname.slice(1, 12)
    let productLang = isGoLife ? 'id' : ''
    if (this.props.history.location.pathname.match('^/mobile/')) {
      productLang = qs.parse(this.props.history.location.search.slice(1)).language_code
    }
    if (isSendoPAEndorse || isSendoBikeEndorse || isGoVietEndorse || isLazadaVn) {
      window.localStorage.getItem('lang', 'vi')
      productLang = 'vi'
    }
    if (isShopeevn) {
      productLang = 'th'
    }

    if (url === 'claimdetail') {
      window.sessionStorage.setItem('claims_internal_id', this.props.history.location.pathname.slice(13))
    }
    this.props.changeLang(language(productLang))
  }
  getLandingPageRoutes () {
    return this.props.domainConfigs.map(({ product, config }) => {
      const propsObj = {
        history: this.props.history,
        config
      }
      if (product) {
        return (
          <Route
            exact='exact'
            path={`/${product}`}
            render={() => React.createElement(whiteLabelGenerateOtp, propsObj)}
          />
        )
      }
      return null
    })
  }
  errorReporter = (error) => {
    const split = error.split('"')
    const value = JSON.stringify(split[1])
    TagManager.dataLayer({
      dataLayer: {
        event: 'undefinedTranslation',
        selectedValue: value
      }
    })
  }

  render () {
    const {
      history,
      isLoggedIn,
      language,
      messages,
      loadingLanguage,
      domainConfigs,
      isDanaUser
    } = this.props
    const currentPath = history.location.pathname
    const secondaryColor = _.get(domainConfigs, '[0].config.SECONDARY_COLOR', '#0180c8')
    const theme = {
      ...COLORS,
      primaryColor: _.get(domainConfigs, '[0].config.BRAND_COLOR', '#ffd500'),
      secondaryColor,
      linearGradientFirstColor: (secondaryColor === '#0180c8' ? '#2b2171' : secondaryColor)
    }
    if (isDanaUser) {
      window.localStorage.setItem('lang', 'id')
    }
    return (
      <Suspense fallback={<Icon type='loading' spin style={{ color: secondaryColor }} />}>
        <ThemeProvider theme={theme}>
          <LocaleProvider>
            <IntlProvider locale={(language || {}).locale} key={(language || {}).locale} messages={messages || {}} onError={this.errorReporter}>
              <Router>
                <div style={{ width: '100%' }}>
                  {
                    loadingLanguage ? <div className='initial-loader'>
                      <CircularLoader size='24px' isSpining={!!loadingLanguage} />
                    </div> : (
                      <div style={{ width: '100%' }}>
                        {!isDanaUser && (
                          currentPath.match('^/tokopedia/shipping') ? <ShippingHeader />
                            : currentPath.match('^/mobile/') ? '' : <HeaderV2 />
                        )}
                        <Switch>
                          <Route
                            exact='exact'
                            path={'/generate-otp'}
                            render={({ location }) => {
                              const params = qs.parse(history.location.search.slice(1))
                              if (params.id) {
                                window.sessionStorage.setItem('internal_id', params.id)
                              }
                              if ((((window.history.state || {}).state || {}).from || {}).pathname === '/gojek-passenger/claim') {
                                window.sessionStorage.setItem('isFromGojek', true)
                                window.sessionStorage.setItem('isFromGoDriver', false)
                                window.sessionStorage.setItem('isFromGolife', false)
                                return React.createElement(generateOtp, { history })
                              } else if ((((window.history.state || {}).state || {}).from || {}).pathname === '/go-driver/claim') {
                                window.sessionStorage.setItem('isFromGojek', false)
                                window.sessionStorage.setItem('isFromGoDriver', true)
                                window.sessionStorage.setItem('isFromGolife', false)
                                return React.createElement(generateOtp, { history })
                              } else if ((((window.history.state || {}).state || {}).from || {}).pathname === '/go-life/claim') {
                                window.sessionStorage.setItem('isFromGolife', true)
                                window.sessionStorage.setItem('isFromGojek', false)
                                window.sessionStorage.setItem('isFromGoDriver', false)
                                return React.createElement(generateOtp, { history })
                              } else {
                                window.sessionStorage.setItem('isFromGojek', false)
                                window.sessionStorage.setItem('isFromGoDriver', false)
                                window.sessionStorage.setItem('isFromGolife', false)
                                return React.createElement(generateGenericOtp, { history, location })
                              }
                            }}
                          />
                          {this.getLandingPageRoutes()}
                          <Route exact='exact' path={'/verify'} render={() => {
                            const fromGojek = window.sessionStorage.getItem('isFromGojek')
                            const fromGoLife = window.sessionStorage.getItem('isFromGolife')
                            const fromGoDriver = window.sessionStorage.getItem('isFromGoDriver')
                            if ((fromGojek === 'true') || (fromGoLife === 'true') || (fromGoDriver === 'true')) {
                              return React.createElement(generateOtp, { history })
                            } else {
                              return React.createElement(generateGenericOtp, { history })
                            }
                          }} />
                          <Route exact='exact' path={'/lazada/hospital-cash-plan/endorse/:id'} render={(props) => {
                            return React.createElement(lazadaHospitalCashPlan, {
                              ...props
                            })
                          }} />
                          <Route exact='exact' path={'/lazada-full-gadget-protection/endorse/:id'} render={(props) => {
                            return React.createElement(lazadaGadgetProtection, {
                              ...props
                            })
                          }} />
                          <Route exact='exact' path={'/goviet-hotel-stay-protection/endorse/:id'} render={(props) => {
                            return React.createElement(goVietHotelStayProtection, {
                              ...props
                            })
                          }} />
                          <Route exact='exact' path={'/shopee/full-gadget/endorse/:id'} render={(props) => {
                            return React.createElement(shopeeEndorsementFullGadget, {
                              ...props
                            })
                          }} />
                          <Route exact='exact' path={'/shopeevn/full-gadget/endorse/:id'} render={(props) => {
                            return React.createElement(shopeeVatGadgetProtectionEndorsement, {
                              ...props
                            })
                          }} />
                          <Route exact='exact' path={'/shopeevn/mobile-insurance/endorse/:id'} render={(props) => {
                            return React.createElement(shopeeVatMobileInsuranceEndorsement, {
                              ...props
                            })
                          }} />
                          <Route exact='exact' path={'/shopeethai/electronics/endorse/:id'} render={(props) => {
                            return React.createElement(shopeeThaiElectronicsEndorsement, {
                              ...props
                            })
                          }} />
                          <Route exact='exact' path={'/shopee/crack-screen-protection/endorse/:id'} render={(props) => {
                            return React.createElement(shopeeEndorsementCrackScreen, {
                              ...props
                            })
                          }} />
                          <Route exact='exact' path={'/xiaomi/gadget-insurance/endorse/:id'} render={(props) => {
                            return React.createElement(shopeeEndorsementFullGadget, {
                              ...props
                            })
                          }} />
                          <Route exact='exact' path={'/gosure/gamer-insurance/endorse/:id'} render={(props) => {
                            return React.createElement(gosureGamerEndorsement, {
                              ...props
                            })
                          }} />
                          <Route exact='exact' path={'/gosure/personal-accident-insurance/endorse/:id'} render={(props) => {
                            return React.createElement(gosurePAEndorsement, {
                              ...props
                            })
                          }} />
                          <Route exact='exact' path={'/gosure/sharia-insurance/endorse/:id'} render={(props) => {
                            return React.createElement(gosureTermLifeEndorsement, {
                              ...props
                            })
                          }} />
                          <Route exact='exact' path={'/gosure/cancer-insurance/endorse/:id'} render={(props) => {
                            return React.createElement(gosureCancerEndorsement, {
                              ...props
                            })
                          }} />
                          <Route exact='exact' path={'/rukita/vehicle-insurance/endorse/:id'} render={(props) => {
                            return React.createElement(rukitaVehicleEndorsement, {
                              ...props
                            })
                          }} />
                          <Route exact='exact' path={'/lazada-crack-screen/endorse/:id'} render={(props) => {
                            return React.createElement(lazadaCrackScreen, {
                              ...props
                            })
                          }} />
                          <Route exact='exact' path={'/lazada-vehicle-protection/endorse/:id'} render={(props) => {
                            return React.createElement(lazadaVehicleProtection, {
                              ...props
                            })
                          }} />
                          <Route path={'/feedback/:id/:rating'} render={(props) => {
                            return React.createElement(feedback, {
                              ...props
                            })
                          }} />
                          <Route path={'/tokopedia/shipping'} render={(props) => {
                            return React.createElement(shipping, {
                              ...props
                            })
                          }} />
                          <Route path={'/tokopedia/shipping/return'} render={(props) => {
                            return React.createElement(ShippingReturn, {
                              ...props
                            })
                          }} />
                          <Route path={'/tokopedia/endorse'} render={(props) => {
                            return React.createElement(tokopediaEndorse, {
                              ...props
                            })
                          }} />
                          <Route path={'/tokopedia/crackscreen/endorse'} render={(props) => {
                            return React.createElement(TokopediaCrackScreenEndorse, {
                              ...props
                            })
                          }} />
                          <Route path={'/gosure/corona/endorse/:id'} render={(props) => {
                            return React.createElement(GosureCoronaEndorse, {
                              ...props
                            })
                          }} />
                          <Route path={'/firstmedia/endorse'} render={(props) => {
                            return React.createElement(firstMediaEndorse, {
                              ...props
                            })
                          }} />
                          <Route path={'/mobile/terms'} render={(props) => {
                            return React.createElement(termsAndCondition, {
                              ...props
                            })
                          }} />
                          <Route path={'/mobile/privacy'} render={(props) => {
                            return React.createElement(privacy, {
                              ...props
                            })
                          }} />
                          <Route path={'/mobile/vehicle-terms'} render={(props) => {
                            return React.createElement(vehicleTerms, {
                              ...props
                            })
                          }} />
                          <Route path={'/mobile/referral-terms'} render={(props) => {
                            return React.createElement(referralTerms, {
                              ...props
                            })
                          }} />
                          <Route path={'/mobile/mobile-app-lock'} render={(props) => {
                            return React.createElement(mobileLockGif, {
                              ...props
                            })
                          }} />
                          <Route path={'/agency/login'} render={(props) => {
                            return React.createElement(mitraSSOLogin, {
                              ...props
                            })
                          }} />
                          <Route exact='exact' path={'/credit-insurance/moladin/endorse/:id/:token'} render={(props) => {
                            return React.createElement(moladinEndorsement, {
                              ...props
                            })
                          }} />
                          <Route exact='exact' path={'/mobile/product-liability/lazada/endorse/:id/:token'} render={(props) => {
                            return React.createElement(lazadaProductLiabilityEndorsement, {
                              ...props
                            })
                          }} />
                          <RestrictedRoutes path='/' component={App} isLoggedIn={isLoggedIn} />
                        </Switch>
                      </div>
                    )
                  }
                </div>
              </Router>
            </IntlProvider>
          </LocaleProvider>
        </ThemeProvider>
      </Suspense >
    )
  }
}

const PublicRoutesConnected = connect(state => ({
  isLoggedIn: state.Auth.authStatus,
  language: state.App.language,
  messages: state.App.messages,
  isDanaUser: state.User.isDanaUser,
  loadingLanguage: state.Loading.CHANGE_LANG
}), { changeLang })(PublicRoutes)

class ProductAppendedRouter extends Component {
  componentDidMount () {
    this.props.getDomainConfigs()
  }
  render () {
    const { domainConfigsLoading, domainConfigs, history } = this.props
    return (
      <React.Fragment>
        {
          domainConfigsLoading ? <div className='initial-loader'>
            <CircularLoader size='24px' isSpining={domainConfigsLoading} />
          </div> : <PublicRoutesConnected history={history} domainConfigs={domainConfigs} />
        }
      </React.Fragment>
    )
  }
}

ProductAppendedRouter.propTypes = {
  domainConfigs: PropTypes.array.isRequired,
  domainConfigsLoading: PropTypes.bool.isRequired
}

ProductAppendedRouter.defaultProps = {
  domainConfigs: [],
  domainConfigsLoading: true
}

export default connect(state => ({
  domainConfigs: state.App.domainConfigs,
  domainConfigsLoading: state.Loading.GET_DOMAIN_CONFIGS,
  domainConfigsError: state.Error.GET_DOMAIN_CONFIGS
}), { getDomainConfigs })(ProductAppendedRouter)
